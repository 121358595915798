import { vec3 } from "gl-matrix";
import Sprite from "./sprite";
import explosionImageUrl from "../assets/explosion.png";

export default class Explosion extends Sprite {
  name = "explosion";
  constructor(gl: WebGLRenderingContext) {
    super(gl, explosionImageUrl);
    this.tileSize = [46, 46];
    this.tileSetDimensions = [506, 46];
    this.numAnimationFrames = 10;
    this.repeats = false;
    this.animationSpeed = 100;

    this.setScale(2.5, 2.5, 2.5);
  }
}
