import { Settings } from "./interfaces";

const DEFAULT_SETTINGS = {
  soundOn: true,
  hiScore: 0,
  snakePieces: 0,
};

export default class SettingsSaver implements Settings {
  get settingsFromLocalStorage(): Settings {
    let settings = { ...DEFAULT_SETTINGS };
    try {
      settings = JSON.parse(localStorage.getItem("settings") || JSON.stringify(DEFAULT_SETTINGS));
    } catch (e) {
      console.error(`Error parsing settings: ${e}`);
      settings = { ...DEFAULT_SETTINGS };
    }

    return settings;
  }

  get soundOn() {
    return this.settingsFromLocalStorage.soundOn;
  }

  set soundOn(on: boolean) {
    const settings = this.settingsFromLocalStorage;
    settings.soundOn = on;
    this.saveSettings(settings);
  }

  get hiScore() {
    return this.settingsFromLocalStorage.hiScore;
  }

  set hiScore(score: number) {
    const settings = this.settingsFromLocalStorage;
    settings.hiScore = score;
    this.saveSettings(settings);
  }

  get snakePieces() {
    return this.settingsFromLocalStorage.snakePieces;
  }

  set snakePieces(snakePieces: number) {
    const settings = this.settingsFromLocalStorage;
    settings.snakePieces = snakePieces;
    this.saveSettings(settings);
  }

  saveSettings(settings: Settings) {
    localStorage.setItem("settings", JSON.stringify(settings));
  }
}
