import { vec3 } from "gl-matrix";
import Sprite from "./sprite";
import skullBenImageUrl from "../assets/skull-ben.png";

export default class SkullBen extends Sprite {
  public benNumber: number = 1;

  name = "skull-ben";

  constructor(gl: WebGLRenderingContext) {
    super(gl, skullBenImageUrl);
    this.tileSize = [128, 128];
    this.tileSetDimensions = [128, 128];
  }

  update(timestamp) {
    const x = Math.cos(timestamp / 100) * 0.06;
    // const y = Math.sin(timestamp / 1000 + timeOffset) * wiggleVel[1];
    const y = 0;
    const z = 0.05 + 0.022 * this.benNumber;
    vec3.set(this.vel, x, y, z);
    super.update(timestamp);
  }
}
