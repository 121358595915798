import { fromEvent, merge, interval } from "rxjs";
import { map, switchMap, takeUntil, filter } from "rxjs/operators";

import "./style.css";
import Sprite, { Box } from "./sprite";
import textureUrl from "../assets/coral-4.png";
import normalTextureUrl from "../assets/coral-4-n.png";
import SpriteRenderer from "./sprite-renderer";
import DialogueBox from "./dialogue-box";
import SettingsSaver from "./settings-saver";
import AudioPlayback from "./audio-playback";

import { mat4, vec2, vec3 } from "gl-matrix";

const bulletAudioUrl = require("../assets/bullet.wav").default;
const explosionAudioUrl = require("../assets/explosion.wav").default;
const hurtAudioUrl = require("../assets/hurt.wav").default;
const gameOverAudioUrl = require("../assets/game-over.wav").default;
const startAudioUrl = require("../assets/start.wav").default;
const specialOnScreenAudioUrl = require("../assets/special-on-screen.wav").default;
const explodeSpecialAudioUrl = require("../assets/explosion-special.wav").default;

const joinOrDie0Url = require("../assets/join-or-die-0.png").default;
const joinOrDie1Url = require("../assets/join-or-die-1.png").default;
const joinOrDie2Url = require("../assets/join-or-die-2.png").default;
const joinOrDie3Url = require("../assets/join-or-die-3.png").default;
const joinOrDie4Url = require("../assets/join-or-die-4.png").default;
const joinOrDie5Url = require("../assets/join-or-die-5.png").default;
const joinOrDie6Url = require("../assets/join-or-die-6.png").default;
const joinOrDie7Url = require("../assets/join-or-die-7.png").default;
const joinOrDie8Url = require("../assets/join-or-die-8.png").default;

const joinOrDieUrls = [
  joinOrDie0Url,
  joinOrDie1Url,
  joinOrDie2Url,
  joinOrDie3Url,
  joinOrDie4Url,
  joinOrDie5Url,
  joinOrDie6Url,
  joinOrDie7Url,
  joinOrDie8Url,
];

import {
  BufferBag,
  compiledProgram,
  cacheAttributeLocations,
  cacheUniformLocations,
  setNormalsAttrib,
  setTangentsAttrib,
  setBitangentsAttrib,
  setPositionsAttrib,
  setTextureCoordsAttrib,
  loadTexture,
  ProgramCache,
} from "./helpers/webgl-helpers";

import FLOOR_FRAG_SHADER from "./shaders/floor-fragment.glsl";
import SPRITE_VERT_SHADER from "./shaders/sprite-vertex.glsl";
import SPRITE_FRAG_SHADER from "./shaders/sprite-fragment.glsl";

const UNIFORM_NAMES = [
  "uModelMatrix",
  "uViewMatrix",
  "uProjectionMatrix",
  "uSpriteSampler",
  "uNormalSampler",
  "uNormals",
  "uLightPos",
  "uTime",
];

const ATTRIBUTE_NAMES = [
  "aVertexPosition",
  "aVertexNormal",
  "aVertexTangent",
  "aVertexBitangent",
  "aTextureCoord",
];

// prettier-ignore
export const QUAD_POSITIONS = [
  -0.5, +0.5, 0.0,
  -0.5, -0.5, 0.0,
  +0.5, +0.5, 0.0,
  -0.5, -0.5, 0.0,
  +0.5, -0.5, 0.0,
  +0.5, +0.5, 0.0,
];

// prettier-ignore
export const TEXTURE_COORDS = [
  0, 0,
  0, 1,
  1, 0,
  0, 1,
  1, 1,
  1, 0
];

const gameCanvas = <HTMLCanvasElement>document.getElementById("game");
const gameGl = gameCanvas.getContext("webgl", { alpha: true });

const hiScoreEl = <HTMLDivElement>document.querySelector("#hi-score");
const currentScoreEl = <HTMLDivElement>document.querySelector("#current-score");
const heartsEl = <HTMLDivElement>document.querySelector("#hearts");
const soundEl = <HTMLDivElement>document.querySelector("#audio-toggle");
const joinOrDieEl = <HTMLDivElement>document.querySelector("#join-or-die");

const settingsSaver = new SettingsSaver();

const audioPlayback = AudioPlayback.getInstance();
audioPlayback.add("bullet", bulletAudioUrl);
audioPlayback.add("explosion", explosionAudioUrl);
audioPlayback.add("hurt", hurtAudioUrl);
audioPlayback.add("game-over", gameOverAudioUrl);
audioPlayback.add("start", startAudioUrl);
audioPlayback.add("special-on-screen", specialOnScreenAudioUrl);
audioPlayback.add("explosion-special", explodeSpecialAudioUrl);

const CHANCE_OF_SKULL_BEN_SPAWN = 0.005;
const MIN_TIME_BETWEEN_SKULL_BENS = 10000;
const numTiles = 200;
const numTilesPerRow = 20;
const zNear = 0.1;
const zFar = 100.0;
const cameraY = 0.75;
const camera: vec3 = [numTilesPerRow / 2, cameraY, 1.0];
const fieldOfView = deg2rad(45);
const rotation = -Math.PI / 2.1;
const lightPos = [5, 2.5, -2.5];
const MIN_SKULL_X = 7;
const SCORE_INCREMENT = 25;
const state = {
  hearts: 3,
  hiScore: settingsSaver.hiScore,
  currentScore: 0,
  intro: true,
  gameOver: false,
  victory: false,
  lastHitTime: -1,
  lastSkullBenSeenTime: -1,
  snakePieces: settingsSaver.snakePieces,
};

let vertexPositions = [];
let textureCoords = [];
const MIN_SKULLS = 3;
let maxSkulls = MIN_SKULLS;

const INTRO_WORDS =
  "HOW DARE YOU DISTURB MY ALIEN SKULL SNAKES. AND DON'T EVEN TRY TO COLLECT ALL EIGHT OF MY BEN FRANKLIN SNAKE PIECES.";

const GAME_OVER_WORDS =
  "HAHA... YOU MAY TRY AGAIN BUT MY ALIEN SKULL SNAKES WILL SURELY DEVOUR YOUR SOUL. WHATEVER YOU DO THOUGH, DO *NOT* COLLECT ALL EIGHT OF MY BEN FRANKLIN SNAKE PIECES.";

const VICTORY_WORDS =
  "YOU WON. GOOD FOR YOU. NOW GIVE ME BACK MY BEN FRANKLIN SNAKE PIECES, AND SEE IF YOU CAN DO IT AGAIN. DON'T EXPECT ME TO TAKE IT SO EASY ON YOU THIS TIME.";

const introDialogueBox = new DialogueBox(INTRO_WORDS);
introDialogueBox.append(document.body);
introDialogueBox.listenForClicks(() => {
  introDialogueBox.handleClick();

  if (!introDialogueBox.onPage) {
    state.intro = false;
    audioPlayback.play("start");
  }
});

const gameOverDialogueBox = new DialogueBox(GAME_OVER_WORDS);
const gameOverCallback = () => {
  gameOverDialogueBox.handleClick();

  if (!gameOverDialogueBox.onPage) {
    state.gameOver = false;
    state.hearts = 3;
    state.currentScore = 0;
    maxSkulls = MIN_SKULLS;
  }
};

const victoryDialogueBox = new DialogueBox(VICTORY_WORDS);
const victoryCallback = () => {
  victoryDialogueBox.handleClick();

  if (!victoryDialogueBox.onPage) {
    state.victory = false;
    state.intro = false;
    state.gameOver = false;
    state.hearts = 3;
    state.currentScore = 0;
    state.snakePieces = 0;
    settingsSaver.snakePieces = 0;
    maxSkulls = MIN_SKULLS;
  }
};

const crosshairs = document.querySelector<HTMLDivElement>("#crosshairs");
crosshairs.style.top = `50px`;
crosshairs.style.left = `${window.innerWidth / 2 - 25}px`;

const convertToTouch = (touchEvent: TouchEvent) => touchEvent.changedTouches[0];

const mousedown$ = merge(
  fromEvent<MouseEvent>(document, "mousedown"),
  fromEvent<TouchEvent>(document, "touchstart").pipe(map(convertToTouch))
).pipe(filter(() => !snakeTalking()));

const mousemove$ = merge(
  fromEvent<MouseEvent>(document, "mousemove"),
  fromEvent<TouchEvent>(document, "touchmove").pipe(map(convertToTouch))
);
const mouseup$ = merge(
  fromEvent<MouseEvent>(document, "mouseup"),
  fromEvent<TouchEvent>(document, "touchend").pipe(map(convertToTouch))
);

const drag$ = mousedown$.pipe(
  switchMap((start) => {
    const top = parseFloat(crosshairs.style.top.split("px")[0]);
    const left = parseFloat(crosshairs.style.left.split("px")[0]);
    const relativeStart = { clientX: start.clientX - left, clientY: start.clientY - top };
    return mousemove$.pipe(
      map((move) => {
        return {
          left: move.clientX - relativeStart.clientX,
          top: move.clientY - relativeStart.clientY,
        };
      }),
      takeUntil(mouseup$)
    );
  })
);

mousedown$.pipe(switchMap(() => interval(100).pipe(takeUntil(mouseup$)))).subscribe(() => {
  const bounds = crosshairs.getBoundingClientRect();
  const { x, y, width, height } = bounds;
  const z = -10;
  const p = unprojectPoint(
    document.body,
    [x - width / 2, y + height / 2],
    z,
    inverseViewProjectionMatrix,
    camera
  );
  const bullet = spriteRenderer.firstInactiveBullet;
  if (!bullet) return;
  bullet.active = true;
  vec3.set(bullet.pos, p[0], p[1], 3);
  audioPlayback.play("bullet");
});

drag$.subscribe((pos) => {
  crosshairs.style.top = `${pos.top}px`;
  crosshairs.style.left = `${pos.left}px`;
});

// Toggle audio
merge(
  fromEvent<MouseEvent>(soundEl, "mouseup"),
  fromEvent<TouchEvent>(soundEl, "touchend").pipe(map(convertToTouch))
).subscribe(() => {
  settingsSaver.soundOn = !settingsSaver.soundOn;
});

// Build up our grid
for (let i = 0; i < numTiles; i++) {
  const quad = [...QUAD_POSITIONS];

  for (let j = 0; j < quad.length; j = j + 3) {
    const y = Math.floor(i / numTilesPerRow);
    quad[j + 0] += i % numTilesPerRow; // x
    quad[j + 1] += y; // y
    quad[j + 2] += 0; // z (doesn't move)
  }

  textureCoords.push(...TEXTURE_COORDS);
  vertexPositions.push(...quad);
}

const quadLength = QUAD_POSITIONS.length;

for (let i = 0; i < numTiles; i++) {
  for (let j = 0; j < quadLength; j = j + 3) {
    const index = i * quadLength + j;
    const v = vec3.create();
    const x = vertexPositions[index + 0];
    const y = vertexPositions[index + 1];
    const z = vertexPositions[index + 2];
    vec3.set(v, x, y, z);
    vec3.rotateX(v, v, [numTiles / 2, 0, 0], rotation);

    vertexPositions[index + 0] = v[0];
    vertexPositions[index + 1] = v[1];
    vertexPositions[index + 2] = v[2];
  }
}

const tbn = generateTbn();

let vertexTangents = [];
let vertexBitangents = [];
let vertexNormals = [];

for (let i = 0; i < vertexPositions.length / 3; i++) {
  vertexTangents.push(...Array.from(tbn.tangent));
  vertexBitangents.push(...Array.from(tbn.bitangent));
  vertexNormals.push(...Array.from(tbn.normal));
}

let normalProgramTexture = loadTexture(gameGl, textureUrl);
let normalProgramNormalTexture = loadTexture(gameGl, normalTextureUrl);

const projectionMatrix = mat4.create();
const modelMatrix = mat4.create();
const viewMatrix = mat4.create();
const viewProjectionMatrix = mat4.create();
const inverseViewProjectionMatrix = mat4.create();

const floorBuffers: BufferBag = {
  positions: gameGl.createBuffer(),
  normals: gameGl.createBuffer(),
  textureCoords: gameGl.createBuffer(),
  tangents: gameGl.createBuffer(),
  bitangents: gameGl.createBuffer(),
};

const floorProgram = compiledProgram(gameGl, SPRITE_VERT_SHADER, FLOOR_FRAG_SHADER);
const floorProgramCache: ProgramCache = {
  attributes: {},
  uniforms: {},
  program: floorProgram,
  shared: { modelMatrix, projectionMatrix, viewMatrix },
};
cacheAttributeLocations(gameGl, floorProgram, floorProgramCache, ATTRIBUTE_NAMES);
cacheUniformLocations(gameGl, floorProgram, floorProgramCache, UNIFORM_NAMES);

const spriteProgram = compiledProgram(gameGl, SPRITE_VERT_SHADER, SPRITE_FRAG_SHADER);
const spriteProgramCache: ProgramCache = {
  attributes: {},
  uniforms: {},
  program: spriteProgram,
  shared: { modelMatrix, projectionMatrix, viewMatrix },
};
cacheAttributeLocations(gameGl, spriteProgram, spriteProgramCache, ATTRIBUTE_NAMES);
cacheUniformLocations(gameGl, spriteProgram, spriteProgramCache, UNIFORM_NAMES);
const spriteRenderer = new SpriteRenderer(gameGl, spriteProgramCache);

function render(time) {
  requestAnimationFrame(render);
  maxSkulls = MIN_SKULLS + Math.floor(Math.log(time));
  if (state.lastHitTime > 0) {
    const diff = time - state.lastHitTime;
    camera[0] = camera[0] + (5 * Math.sin(time / 10)) / diff;
    camera[1] = camera[1] + (5 * Math.sin(time / 10)) / diff;
  }

  camera[1] = cameraY + Math.sin(time / 1000) * 0.05;
  const bounds = gamePageBounds(inverseViewProjectionMatrix, camera);

  spriteRenderer.activeSprites.forEach((sprite) => {
    sprite.update(time);

    switch (sprite.name) {
      case "bullet":
        // remove off screen bullets
        if (sprite.pos[2] < -10) sprite.active = false;

        // skull/bullet collision detection
        const hits = spriteRenderer.activeSkulls.filter((skull) => {
          return pointInAABB(sprite.pos, skull.bounds) ? skull : null;
        });

        if (hits.length) {
          const skull = hits[0];
          const explosion = spriteRenderer.firstInactiveExplosion;
          vec3.set(explosion.pos, skull.pos[0], skull.pos[1], skull.pos[2]);
          explosion.tileIndex = 0;
          explosion.active = true;
          skull.active = false;
          sprite.active = false;

          if (skull.name === "skull-ben") {
            audioPlayback.play("explosion-special");
            const playAudio = () => audioPlayback.play("explosion-special");
            setTimeout(playAudio, 100);
            setTimeout(playAudio, 200);
            setTimeout(playAudio, 300);
            setTimeout(playAudio, 400);
            setTimeout(playAudio, 500);
            setTimeout(playAudio, 600);
            setTimeout(playAudio, 700);
            setTimeout(playAudio, 800);
            setTimeout(playAudio, 900);
            let snakePieces = settingsSaver.snakePieces;
            snakePieces = Math.min(snakePieces + 1, 8);
            settingsSaver.snakePieces = snakePieces;
            state.snakePieces = snakePieces;
          } else {
            audioPlayback.play("explosion");
          }

          state.currentScore += SCORE_INCREMENT;
        }
        break;
      case "explosion":
        if (sprite.tileIndex === 9) {
          sprite.active = false;
          sprite.tileIndex = 0;
        }
        break;
      case "skull":
        // player can get hit
        if (sprite.pos[2] > 1) playerGotHitBySprite(sprite, time);
        break;
      case "skull-ben":
        // player can get hit
        if (sprite.pos[2] > 1) playerGotHitBySprite(sprite, time);
        break;
    }
  });

  // figure out how many skulls to add
  const skullsToAdd = maxSkulls - spriteRenderer.numActiveSkulls;

  // pick enemy skull starting position
  const start: vec3 = [
    randomNumberBetween(bounds.left, bounds.right),
    randomNumberBetween(bounds.top, bounds.bottom),
    -13,
  ];

  if (!state.intro && !state.gameOver && !state.victory) {
    // add enemy skulls
    for (let i = 0; i < skullsToAdd; i++) {
      if (start[0] < MIN_SKULL_X) continue;
      const offset = i * 0.1;
      const skull = spriteRenderer.firstInactiveSkull;
      skull.active = true;
      skull.timeOffset = offset;
      vec3.set(skull.wiggleVel, 0.012, 0.012, 0.018);
      vec3.set(skull.pos, start[0] + offset, start[1] + offset, start[2] + offset);
    }

    // try to spawn a skull ben
    if (state.lastSkullBenSeenTime < -1) state.lastSkullBenSeenTime = time;
    if (
      time - state.lastSkullBenSeenTime > MIN_TIME_BETWEEN_SKULL_BENS &&
      Math.random() < CHANCE_OF_SKULL_BEN_SPAWN
    ) {
      const start: vec3 = [
        randomNumberBetween(bounds.left, bounds.right),
        randomNumberBetween(bounds.top, bounds.bottom),
        -13,
      ];
      const skullBen = spriteRenderer.firstInactiveSkullBen;
      skullBen.benNumber = state.snakePieces + 1;
      vec3.set(skullBen.pos, start[0], start[1], -13);
      skullBen.active = true;
      audioPlayback.play("special-on-screen");
      const playAudio = () => audioPlayback.play("special-on-screen");
      setTimeout(playAudio, 100);
      setTimeout(playAudio, 105);
      setTimeout(playAudio, 110);
      setTimeout(playAudio, 115);
      setTimeout(playAudio, 120);
      setTimeout(playAudio, 125);
      setTimeout(playAudio, 135);
      setTimeout(playAudio, 140);
      setTimeout(playAudio, 145);
      setTimeout(playAudio, 155);
      setTimeout(playAudio, 160);
      setTimeout(playAudio, 165);
      setTimeout(playAudio, 170);
      setTimeout(playAudio, 175);
      setTimeout(playAudio, 180);
      setTimeout(playAudio, 185);
      setTimeout(playAudio, 190);
      state.lastSkullBenSeenTime = time;
    }
  }

  // update matrices
  mat4.lookAt(
    viewMatrix,
    [camera[0], camera[1], camera[2]],
    [camera[0], camera[1], 0],
    [0.0, 1.0, 0.0]
  );
  mat4.perspective(projectionMatrix, fieldOfView, getAspectRatio(gameGl.canvas), zNear, zFar);
  mat4.multiply(viewProjectionMatrix, projectionMatrix, viewMatrix);
  mat4.invert(inverseViewProjectionMatrix, viewProjectionMatrix);

  // render floor
  renderFloor(
    gameGl,
    floorProgram,
    time,
    floorProgramCache,
    floorBuffers,
    normalProgramTexture,
    normalProgramNormalTexture
  );

  // render sprites
  spriteRenderer.render(time);

  // update hi score
  if (state.currentScore > state.hiScore) state.hiScore = state.currentScore;

  // handle game over
  if (state.hearts === 0 && state.gameOver === false) {
    state.gameOver = true;
    audioPlayback.play("game-over");

    // overwrite saved hi score
    if (state.hiScore > settingsSaver.hiScore) {
      settingsSaver.hiScore = state.hiScore;
    }

    gameOverDialogueBox.append(document.body);
    gameOverDialogueBox.listenForClicks(gameOverCallback);
    spriteRenderer.activeSprites.forEach((sprite) => (sprite.active = false));
  }

  // handle victory
  if (state.snakePieces === 8) {
    state.victory = true;
  }

  if (state.victory === true) {
    spriteRenderer.activeSprites.forEach((sprite) => (sprite.active = false));

    if (!victoryDialogueBox.onPage) {
      victoryDialogueBox.append(document.body);
      victoryDialogueBox.listenForClicks(victoryCallback);
    }
  }

  updateUi();
}

requestAnimationFrame(render);

function playerGotHitBySprite(sprite: Sprite, time: number) {
  state.hearts = Math.max(state.hearts - 1, 0);
  sprite.active = false;
  audioPlayback.play("hurt");
  state.lastHitTime = time;
}

function updateUi() {
  hiScoreEl.innerText = `HI: ${state.hiScore}`;
  currentScoreEl.innerText = `SCORE: ${state.currentScore}`;

  heartsEl.innerHTML = "";
  for (let i = 0; i < state.hearts; i++) {
    heartsEl.innerHTML += '<div class="heart"></div>';
  }

  soundEl.innerText = settingsSaver.soundOn ? "AUDIO IS ON" : "AUDIO IS OFF";
  const joinOrDieUrl = joinOrDieUrls[state.snakePieces];
  joinOrDieEl.style.backgroundImage = `url(${joinOrDieUrl})`;
}

function getAspectRatio(canvas) {
  return canvas.clientWidth / canvas.clientHeight;
}

function deg2rad(deg: number) {
  return (deg * Math.PI) / 180;
}

function randomNumberBetween(min, max) {
  return Math.random() * (max - min) + min;
}

function renderFloor(
  gl: WebGLRenderingContext,
  program: WebGLProgram,
  time: number,
  programCache: ProgramCache,
  buffers: BufferBag,
  texture: WebGLTexture,
  normalTexture: WebGLTexture
) {
  gl.useProgram(program);
  gl.clearColor(0.0, 0.0, 0.0, 0.0);
  gl.clearDepth(1.0);
  gl.disable(gl.DEPTH_TEST);
  // gl.enable(gl.DEPTH_TEST);
  // gl.depthFunc(gl.LEQUAL);
  gl.enable(gl.BLEND);
  gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
  gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

  gl.uniformMatrix4fv(programCache.uniforms.uProjectionMatrix, false, projectionMatrix);
  gl.uniformMatrix4fv(programCache.uniforms.uModelMatrix, false, modelMatrix);
  gl.uniformMatrix4fv(programCache.uniforms.uViewMatrix, false, viewMatrix);

  gl.activeTexture(gl.TEXTURE0);
  gl.bindTexture(gl.TEXTURE_2D, texture);

  gl.activeTexture(gl.TEXTURE1);
  gl.bindTexture(gl.TEXTURE_2D, normalTexture);

  gl.uniform1i(programCache.uniforms.uSpriteSampler, 0);
  gl.uniform1i(programCache.uniforms.uNormalSampler, 1);
  gl.uniform1f(programCache.uniforms.uTime, time);

  gl.uniform3fv(programCache.uniforms.uLightPos, lightPos);

  setNormalsAttrib(gl, programCache, buffers["normals"], vertexNormals);
  setTangentsAttrib(gl, programCache, buffers["tangents"], vertexTangents);
  setBitangentsAttrib(gl, programCache, buffers["bitangents"], vertexBitangents);
  setPositionsAttrib(gl, programCache, buffers["positions"], vertexPositions);
  setTextureCoordsAttrib(gl, programCache, buffers["textureCoords"], textureCoords);

  gl.drawArrays(gl.TRIANGLES, 0, vertexPositions.length / 3);
}

// https://stackoverflow.com/questions/13055214/mouse-canvas-x-y-to-three-js-world-x-y-z
export function unprojectPoint(
  element: HTMLElement,
  point: vec2,
  z: number,
  inverseViewProjMat: mat4,
  camera: vec3
) {
  const screenX = point[0];
  const screenY = point[1];
  const x = (screenX / element.clientWidth) * 2 - 1;
  const y = -(screenY / element.clientHeight) * 2 + 1;

  const coords: vec3 = [x, y, 0];
  const worldCoords = vec3.create();

  vec3.transformMat4(worldCoords, coords, inverseViewProjMat);
  vec3.subtract(worldCoords, worldCoords, camera);
  vec3.normalize(worldCoords, worldCoords);
  const distance = (z - camera[2]) / worldCoords[2];
  vec3.scale(worldCoords, worldCoords, distance);
  vec3.add(worldCoords, worldCoords, camera);
  // console.log("camera: ", camera);
  // console.log("screen: ", point);
  // console.log("clip: ", [x, y]);
  // console.log("world: ", worldCoords);
  return worldCoords;
}

function pointInAABB(point: vec3, box: Box) {
  return (
    point[0] >= box.minX &&
    point[0] <= box.maxX &&
    point[1] >= box.minY &&
    point[1] <= box.maxY &&
    point[2] >= box.minZ &&
    point[2] <= box.maxZ
  );
}

// https://learnopengl.com/Advanced-Lighting/Normal-Mapping
function generateTbn() {
  const pos1 = QUAD_POSITIONS.slice(0, 3) as vec3;
  const pos2 = QUAD_POSITIONS.slice(3, 6) as vec3;
  const pos3 = QUAD_POSITIONS.slice(6, 9) as vec3;

  const edge1 = vec3.create();
  const edge2 = vec3.create();

  vec3.subtract(edge1, pos2, pos1);
  vec3.subtract(edge2, pos3, pos1);

  const uv1 = textureCoords.slice(0, 2) as vec2;
  const uv2 = textureCoords.slice(2, 4) as vec2;
  const uv3 = textureCoords.slice(4, 6) as vec2;

  const deltaUv1 = vec2.create();
  const deltaUv2 = vec2.create();

  vec2.subtract(deltaUv1, uv2, uv1);
  vec2.subtract(deltaUv2, uv3, uv1);

  const f = 1 / (deltaUv1[0] * deltaUv2[1] - deltaUv2[0] * deltaUv1[1]);

  const tangent = vec3.create();
  vec3.set(
    tangent,
    deltaUv2[1] * edge1[0] - deltaUv1[1] * edge2[0],
    deltaUv2[1] * edge1[1] - deltaUv1[1] * edge2[1],
    deltaUv2[1] * edge1[2] - deltaUv1[1] * edge2[2]
  );

  vec3.scale(tangent, tangent, f);
  vec3.normalize(tangent, tangent);

  const bitangent = vec3.create();

  vec3.set(
    bitangent,
    -deltaUv2[0] * edge1[0] + deltaUv1[0] * edge2[0],
    -deltaUv2[0] * edge1[1] + deltaUv1[0] * edge2[1],
    -deltaUv2[0] * edge1[2] + deltaUv1[0] * edge2[2]
  );

  vec3.scale(bitangent, bitangent, f);
  vec3.normalize(bitangent, bitangent);

  const normal = vec3.create();
  vec3.set(normal, 0, 0, 1);

  return { normal, tangent, bitangent };
}

function gamePageBounds(inverseViewProjectionMatrix: mat4, camera: vec3): Bounds {
  const topLeft = unprojectPoint(gameCanvas, [0, 0], 0, inverseViewProjectionMatrix, camera);

  const bottomRight = unprojectPoint(
    gameCanvas,
    [window.innerWidth, window.innerHeight],
    0,
    inverseViewProjectionMatrix,
    camera
  );

  return {
    left: topLeft[0],
    top: topLeft[1],
    right: bottomRight[0],
    bottom: bottomRight[1],
  };
}

function snakeTalking() {
  return state.gameOver || state.victory || state.intro;
}

interface Bounds {
  top: number;
  left: number;
  right: number;
  bottom: number;
}
