import { vec3 } from "gl-matrix";

import Sprite from "./sprite";
import bulletImageUrl from "../assets/bullet.png";

export default class Bullet extends Sprite {
  name = "bullet";
  constructor(gl: WebGLRenderingContext) {
    super(gl, bulletImageUrl);
    this.tileSize = [96, 96];
    this.tileSetDimensions = [288, 96];
    this.numAnimationFrames = 3;
    vec3.set(this.vel, 0, 0, -0.2);
  }
}
