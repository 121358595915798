import { vec2, vec3 } from "gl-matrix";

import { QUAD_POSITIONS, TEXTURE_COORDS } from "./index";

import { loadTexture, setHalfPixelTextureCoords, WrapMode } from "./helpers/webgl-helpers";

export default class Sprite {
  static readonly DEFAULT_ANIMATION_SPEED = 25;

  public name: string;
  public pos: vec3 = vec3.create();
  public vel: vec3 = vec3.create();
  public vertexPositions: number[] = [...QUAD_POSITIONS];
  public textureCoords: number[] = [...TEXTURE_COORDS];
  public texture: WebGLTexture;
  public textureUrl: string;
  public active: boolean = false;
  public repeats: boolean = true;
  public tileIndex: number = 0;

  private scale: vec3 = [1, 1, 1];
  private gl: WebGLRenderingContext;

  protected animationSpeed: number = Sprite.DEFAULT_ANIMATION_SPEED;
  protected numAnimationFrames: number = 1;
  protected tileSize: vec2;
  protected tileSetDimensions: vec2;
  protected lastAnimatedAt: number = 0;

  constructor(gl: WebGLRenderingContext, texturePath: string) {
    this.gl = gl;
    this.textureUrl = texturePath;
    this.texture = loadTexture(this.gl, this.textureUrl, WrapMode.CLAMP_TO_EDGE);
  }

  public get bounds() {
    const { scale, pos } = this;
    return {
      minX: pos[0] - scale[0] / 2,
      maxX: pos[0] + scale[0] / 2,
      minY: pos[1] - scale[1] / 2,
      maxY: pos[1] + scale[1] / 2,
      minZ: pos[2] - scale[2] / 2,
      maxZ: pos[2] + scale[2] / 2,
    };
  }

  public update(timestamp: number) {
    if (!this.active) return;
    this.pos[0] += this.vel[0];
    this.pos[1] += this.vel[1];
    this.pos[2] += this.vel[2];

    this.setTextureCoordinates();
    this.updateVertexPositions();

    if (this.tileIndex === this.numAnimationFrames - 1 && !this.repeats) return;

    if (timestamp - this.lastAnimatedAt > this.animationSpeed) {
      this.lastAnimatedAt = timestamp;
      this.tileIndex = (this.tileIndex + 1) % this.numAnimationFrames;
    }
  }

  public setScale(width: number, height: number, depth: number) {
    this.scale = [this.scale[0] * width, this.scale[1] * height, this.scale[2] * depth];
    // Origin is in the middle so we expand from the center
    for (let i = 0; i < this.vertexPositions.length; i = i + 3) {
      this.vertexPositions[i + 0] = this.vertexPositions[i + 0] * width;
      this.vertexPositions[i + 1] = this.vertexPositions[i + 1] * height;
      this.vertexPositions[i + 2] = this.vertexPositions[i + 2] * depth;
    }
    // this.updateTbn();
  }

  protected updateVertexPositions() {
    const { scale } = this;
    for (let i = 0; i < this.vertexPositions.length; i = i + 3) {
      this.vertexPositions[i + 0] = scale[0] * QUAD_POSITIONS[i + 0] + this.pos[0];
      this.vertexPositions[i + 1] = scale[1] * QUAD_POSITIONS[i + 1] + this.pos[1];
      this.vertexPositions[i + 2] = scale[2] * QUAD_POSITIONS[i + 2] + this.pos[2];
    }
  }

  private setTextureCoordinates() {
    const { textureCoords, tileIndex, tileSize, tileSetDimensions } = this;
    setHalfPixelTextureCoords(
      textureCoords,
      TEXTURE_COORDS,
      0,
      tileIndex,
      tileSize,
      tileSetDimensions
    );
  }
}

export interface Box {
  minX: number;
  maxX: number;
  minY: number;
  maxY: number;
  minZ: number;
  maxZ: number;
}
