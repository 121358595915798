import Sprite from "./sprite";
import Bullet from "./bullet";
import Skull from "./skull";
import SkullBen from "./skull-ben";
import Explosion from "./explosion";

const NUM_SKULLS_TO_CACHE = 25;
const NUM_BULLETS_TO_CACHE = 25;
const NUM_EXPLOSIONS_TO_CACHE = 25;
const NUM_SKULL_BENS_TO_CACHE = 10;

import {
  setPositionsAttrib,
  setTextureCoordsAttrib,
  setNormalsAttrib,
  setTangentsAttrib,
  setBitangentsAttrib,
  ProgramCache,
} from "./helpers/webgl-helpers";

export default class SpriteRenderer {
  public sprites: Sprite[];

  private gl: WebGLRenderingContext;
  private cache: ProgramCache;
  private buffers: {
    positions: WebGLBuffer | null;
    normals: WebGLBuffer | null;
    tangents: WebGLBuffer | null;
    bitangents: WebGLBuffer | null;
    textureCoords: WebGLBuffer | null;
    modelMatrices: WebGLBuffer | null;
  };

  constructor(gl: WebGLRenderingContext, cache: ProgramCache) {
    this.gl = gl;
    this.cache = cache;
    this.sprites = [];
    this.buffers = {
      positions: gl.createBuffer(),
      textureCoords: gl.createBuffer(),
      normals: gl.createBuffer(),
      tangents: gl.createBuffer(),
      bitangents: gl.createBuffer(),
      modelMatrices: gl.createBuffer(),
    };

    for (let i = 0; i < NUM_BULLETS_TO_CACHE; i++) {
      this.sprites.push(new Bullet(gl));
    }

    for (let i = 0; i < NUM_SKULLS_TO_CACHE; i++) {
      this.sprites.push(new Skull(gl));
    }

    for (let i = 0; i < NUM_EXPLOSIONS_TO_CACHE; i++) {
      this.sprites.push(new Explosion(gl));
    }

    for (let i = 0; i < NUM_SKULL_BENS_TO_CACHE; i++) {
      this.sprites.push(new SkullBen(gl));
    }
  }

  public get firstInactiveBullet(): Bullet | null {
    let cached = null;
    const start = 0;
    const total = NUM_BULLETS_TO_CACHE;
    for (let i = start; i < total; i++) {
      const sprite: Sprite = this.sprites[i];
      if (!sprite.active) {
        cached = sprite;
        break;
      }
    }

    if (!cached) console.warn("Uh oh, no bullets left...");
    return cached;
  }

  public get activeSprites(): Sprite[] {
    return this.sprites.filter((sprite) => sprite.active);
  }

  public get numActiveSkulls(): number {
    return this.activeSkulls.length;
  }

  public get activeSkulls(): Skull[] {
    let cached = [];
    for (let i = NUM_BULLETS_TO_CACHE; i < NUM_BULLETS_TO_CACHE + NUM_SKULLS_TO_CACHE; i++) {
      const sprite: Sprite = this.sprites[i];
      if (sprite.active) cached.push(sprite);
    }

    const benStart = NUM_BULLETS_TO_CACHE + NUM_SKULLS_TO_CACHE + NUM_EXPLOSIONS_TO_CACHE;
    const benTotal =
      NUM_BULLETS_TO_CACHE +
      NUM_SKULLS_TO_CACHE +
      NUM_EXPLOSIONS_TO_CACHE +
      NUM_SKULL_BENS_TO_CACHE;

    for (let i = benStart; i < benTotal; i++) {
      const sprite: Sprite = this.sprites[i];
      if (sprite.active) cached.push(sprite);
    }

    return cached;
  }

  public get firstInactiveSkull(): Skull | null {
    let cached = null;
    const start = NUM_BULLETS_TO_CACHE;
    for (let i = start; i < NUM_BULLETS_TO_CACHE + NUM_SKULLS_TO_CACHE; i++) {
      const sprite: Sprite = this.sprites[i];
      if (!sprite.active) {
        cached = sprite;
        break;
      }
    }

    if (!cached) console.warn("Uh oh, no skulls left...");
    return cached;
  }

  public get firstInactiveExplosion(): Explosion | null {
    let cached = null;
    const start = NUM_BULLETS_TO_CACHE + NUM_SKULLS_TO_CACHE;
    const total = NUM_BULLETS_TO_CACHE + NUM_SKULLS_TO_CACHE + NUM_EXPLOSIONS_TO_CACHE;
    for (let i = start; i < total; i++) {
      const sprite: Sprite = this.sprites[i];
      if (!sprite.active) {
        cached = sprite;
        break;
      }
    }

    if (!cached) console.warn("Uh oh, no explosions left...");
    return cached;
  }

  public get firstInactiveSkullBen(): SkullBen | null {
    let cached = null;
    const start = NUM_BULLETS_TO_CACHE + NUM_SKULLS_TO_CACHE + NUM_EXPLOSIONS_TO_CACHE;
    const total =
      NUM_BULLETS_TO_CACHE +
      NUM_SKULLS_TO_CACHE +
      NUM_EXPLOSIONS_TO_CACHE +
      NUM_SKULL_BENS_TO_CACHE;
    for (let i = start; i < total; i++) {
      const sprite: Sprite = this.sprites[i];
      if (!sprite.active) {
        cached = sprite;
        break;
      }
    }

    if (!cached) console.warn("Uh oh, no skull-bens left...");
    return cached;
  }

  public removeAll() {
    clearArray(this.sprites);
  }

  public render(timestamp: number) {
    if (this.sprites.length === 0) return;

    const { gl, buffers, cache } = this;

    if (!buffers["positions"]) throw Error(`No positions buffer...`);
    if (!buffers["textureCoords"]) throw Error(`No texture coordinates buffer...`);
    gl.useProgram(cache.program);

    gl.uniformMatrix4fv(cache.uniforms.uProjectionMatrix, false, cache.shared.projectionMatrix);
    gl.uniformMatrix4fv(cache.uniforms.uModelMatrix, false, cache.shared.modelMatrix);
    gl.uniformMatrix4fv(cache.uniforms.uViewMatrix, false, cache.shared.viewMatrix);

    const vertexPositions = [];
    const textureCoords = [];
    const vertexNormals = [];
    const vertexTangents = [];
    const vertexBitangents = [];

    let activeTextureUrl = null;

    // Every time we get a new texture, make a draw call and start over
    for (let i = 0; i < this.sprites.length; i++) {
      const sprite = this.sprites[i];
      if (!sprite.active) continue;

      // If we're switching textures, draw
      if (activeTextureUrl !== sprite.textureUrl) {
        if (activeTextureUrl) {
          this.draw(
            vertexPositions,
            textureCoords,
            vertexNormals,
            vertexTangents,
            vertexBitangents
          );
        }

        const texture = sprite.texture;
        // const normalTexture = sprite.normalTexture;

        gl.activeTexture(gl.TEXTURE0);
        gl.bindTexture(gl.TEXTURE_2D, texture);
        gl.uniform1i(cache.uniforms.uSpriteSampler, 0);

        gl.activeTexture(gl.TEXTURE1);
        // gl.bindTexture(gl.TEXTURE_2D, normalTexture);
        // gl.uniform1i(cache.uniforms.uNormalSampler, 1);

        activeTextureUrl = sprite.textureUrl;
      }

      textureCoords.push(...sprite.textureCoords);
      vertexPositions.push(...sprite.vertexPositions);
      // modelMatrices.push(...Array.from(sprite.modelMatrix));

      // vertexNormals.push(...sprite.vertexNormals);
      // vertexTangents.push(...sprite.vertexTangents);
      // vertexBitangents.push(...sprite.vertexBitangents);
      // opacities.push(...QUAD_POSITIONS.map(() => sprite.opacity));
    }

    // Draw one last time at the end
    this.draw(vertexPositions, textureCoords, vertexNormals, vertexTangents, vertexBitangents);
  }

  draw(vertexPositions, textureCoords, vertexNormals, vertexTangents, vertexBitangents) {
    const { gl, buffers, cache } = this;

    gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, true);

    setPositionsAttrib(gl, cache, buffers["positions"], vertexPositions);
    setTextureCoordsAttrib(gl, cache, buffers["textureCoords"], textureCoords);

    // setPositionsAttrib(gl, programName, state, buffers.positions, vertexPositions);
    // setTextureCoordsAttrib(gl, programName, state, buffers.textureCoords, textureCoords);
    // setNormalsAttrib(gl, programName, state, buffers.normals, vertexNormals);
    // setTangentsAttrib(gl, programName, state, buffers.tangents, vertexTangents);
    // setBitangentsAttrib(gl, programName, state, buffers.bitangents, vertexBitangents);
    gl.drawArrays(gl.TRIANGLES, 0, vertexPositions.length / 3);
    // Remove everything from the arrays to get ready for the next draw call
    clearArray(vertexPositions);
    clearArray(textureCoords);
    clearArray(vertexNormals);
    clearArray(vertexTangents);
    clearArray(vertexBitangents);
  }
}

function clearArray(array) {
  array.splice(0, array.length);
}
