import { vec3 } from "gl-matrix";
import Sprite from "./sprite";
import skullImageUrl from "../assets/skull.png";

export default class Skull extends Sprite {
  public timeOffset: number = 0;
  public wiggleVel: vec3 = vec3.create();

  name = "skull";
  constructor(gl: WebGLRenderingContext) {
    super(gl, skullImageUrl);
    this.tileSize = [100, 100];
    this.tileSetDimensions = [100, 100];
  }

  update(timestamp) {
    const { timeOffset, wiggleVel } = this;
    const x = Math.cos(timestamp / 1000 + timeOffset) * wiggleVel[0];
    const y = Math.sin(timestamp / 1000 + timeOffset) * wiggleVel[1];
    const z = wiggleVel[2];
    vec3.set(this.vel, x, y, z);
    super.update(timestamp);
  }
}
