import { Howl } from "howler";
import SettingsSaver from "./settings-saver";

const soundBag: SoundBag = {};

export default class AudioPlayback {
  private static instance: AudioPlayback;

  private settings: SettingsSaver;

  private constructor() {
    this.settings = new SettingsSaver();
  }

  public static getInstance(): AudioPlayback {
    if (!AudioPlayback.instance) {
      AudioPlayback.instance = new AudioPlayback();
    }

    return AudioPlayback.instance;
  }

  add(name: string, url: string) {
    if (!soundBag[name]) soundBag[name] = new Howl({ src: [url] });
  }

  play(name: string) {
    if (!this.settings.soundOn) return;

    const sound = soundBag[name];
    if (!sound) {
      console.error(`No sound with name ${name}!`);
      return;
    }

    sound.play();
  }
}

interface SoundBag {
  [key: string]: Howl;
}
