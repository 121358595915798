import { fromEvent, merge } from "rxjs";
import { filter, takeWhile, throttleTime } from "rxjs/operators";
import stringHash from "string-hash";

const skullTongueImageUrl = require("../assets/skull-tongue.png").default;

import html from "./templates/dialogue-box.html";

export default class DialogueBox {
  public div: HTMLElement;

  private words: string;
  private dialogueBox: HTMLElement;
  private dialogueId: string;
  private lastLetterSpan: HTMLSpanElement;

  constructor(words: string, bonusId?: string) {
    this.words = words;
    this.dialogueId = `dialogue-${stringHash(words + bonusId)}`;
  }

  get onPage() {
    return !!document.querySelector(`[data-dialogue-id=${this.dialogueId}]`);
  }

  createBox() {
    this.div = document.createElement("div");
    this.div.dataset.dialogueId = this.dialogueId;
    this.div.insertAdjacentHTML("beforeend", html);
    this.dialogueBox = this.div.querySelector(".dialogue-box");
    const skullTongueEl = <HTMLDivElement>this.div.querySelector(".skull-tongue");
    skullTongueEl.style.background = `url(${skullTongueImageUrl})`;

    const lastIndex = this.words.length - 1;
    this.words.split("").forEach((letter, i) => {
      const span = document.createElement("span");
      span.classList.add("letter");
      span.innerText = letter;
      span.style.animationDelay = `${i / 60}s`;
      this.dialogueBox.append(span);
      if (i === lastIndex) this.lastLetterSpan = span;
    });
  }

  append(element: HTMLElement) {
    this.createBox();
    element.append(this.div);
  }

  listenForClicks(callback) {
    const { dialogueBox } = this;
    const onPage = () => this.onPage;
    const keys = [" ", "Enter"];
    const spaceAndEnter = filter((e: KeyboardEvent) => keys.includes(e.key));
    const skullTongue = this.div.querySelector(".skull-tongue");
    const mouseUp$ = fromEvent([skullTongue, dialogueBox], "mouseup");
    const touchEnd$ = fromEvent([skullTongue, dialogueBox], "touchend");
    const keyUp$ = fromEvent(document, "keyup").pipe(spaceAndEnter);
    const click$ = merge(mouseUp$, touchEnd$, keyUp$).pipe(throttleTime(250));
    click$.pipe(takeWhile(onPage)).subscribe(() => callback());
  }

  remove() {
    // not sure why I need this.div.parentNode...
    if (this.onPage && this.div.parentNode) this.div.parentNode.removeChild(this.div);
  }

  handleClick() {
    const { lastLetterSpan } = this;
    const opacity = parseFloat(window.getComputedStyle(lastLetterSpan).getPropertyValue("opacity"));

    if (opacity < 1.0) {
      const spans = this.dialogueBox.querySelectorAll("span");
      spans.forEach((span) => (span.style.animationDelay = ""));
    } else {
      this.remove();
    }
  }
}
